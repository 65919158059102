:root {
  --toastify-toast-top: unset;
  --toastify-toast-bd-radius: unset;
  --toastify-toast-width: 100%;
  --toastify-toast-min-height: unset;
}

.Toastify__toast-container {
  padding: 0;
  margin-top: 8px;
}
